import { useState, useEffect } from "react";
import axios from "axios";
import SelfEmployed from "../components/modals/SelfEmployed";
import BVNVerificationModal from "../components/modals/BVNVerificationModal";
import CreditLimitModal from "../components/modals/CreditLimitModal";
import Employed from "../components/modals/Employed";
import toast from "react-hot-toast";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import Student from "../components/modals/Student";
import { AllBanksModal } from "../components/modals/AllBanksModal";

export const DocumentCheck = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [bvnModalOpen, setBvnModalOpen] = useState(false);
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [selfEmployedModalOpen, setSelfEmployedModalOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const [selectedBvn, setSelectedBvn] = useState<any>({});
  const [selectedInfo, setSelectedInfo] = useState<any>({});
  const [creditLimit, setCreditLimit] = useState("");
  const [action1, setAction1] = useState(false);
  const [disapproveMsg, setDisapproveMsg] = useState("");
  const [action2, setAction2] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [employedModalOpen, setEmployedModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [allBanksDetails, setAllBanksDetails] = useState([]);
  const [allBanksModal, setAllBanksModal] = useState(false);
  const [id, setId] = useState("");
  const [documentCheckPage, setDocumentCheckPage] = useState(false)

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/verification-stage-two`
      );
      console.log(res.data);

      setIsLoading(false);
      const flexibleUsers = res.data.customers.filter(
        (user: any) => user.accountType === "flexible"
      );
      setUsers(flexibleUsers);
    } catch (error) {
      console.log(error);
      setUsers([]);
      setIsLoading(false);
    }
  };

  const approveCustomerHandler = async () => {
    const checkCreditLimit: any = users.filter((user: any) => user._id === id);
    console.log(checkCreditLimit[0].isWallet);
    if (!checkCreditLimit[0].isWallet) {
      console.log(checkCreditLimit[0].isWallet);

      toast.error("Set credit limit first!");
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/approveStageTwoProcess/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(res.data);
      if (res.status === 200) {
        setId("");
        setAction2("");
        toast.success(res.data.message);
        getAllUsers();
        createVirtualAcct(id);
        createMonoAcct(id);
      }
    } catch (error) {
      console.log(error);
      setAction2("");
      toast.error("Error approving user, please try again later");
    }
  };

  const createVirtualAcct = async (id: string) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/create-virtual-account/${id}`
      );
      console.log("createVA", res);
    } catch (error) {
      console.log(error);
    }
  };

  const createMonoAcct = async (id: string) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/create-mono-account/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("createMono", res);
    } catch (error) {
      console.log(error);
    }
  };

  const disaproveCustomerHandler = async () => {
    if (!disapproveMsg) return;
    const payload = {
      reason: disapproveMsg,
    };
    console.log(payload, id);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/disapproveStageTwoProcess/${id}`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(res.data);
      setId("");
      setDisapproveMsg("");
      setAction2("");
      toast.success(res.data.message);
      getAllUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAction = (e: any) => {
    setAction2(e.target.value);
  };

  const handleClearSelection = (e: any) => {
    setAction2("");

    const selectElement = document.getElementById("select") as any;
    if (selectElement) {
      selectElement.selectedIndex = 0;
    }
  };

  const handleBvnClick = (bvn: string) => {
    setSelectedBvn(bvn);
    setBvnModalOpen(true);
  };

  const handleJobClick = (details: string, occupation: any) => {
    setSelectedInfo(details);
    if (occupation === "Self-Employed") {
      setSelfEmployedModalOpen(true);
    } else if (occupation === "Employed") {
      setEmployedModalOpen(true);
    } else {
      setStudentModalOpen(true);
    }
  };

  const handleViewAllBank = (id: string, linkedBanks: any) => {
    setUserId(id);
    setAllBanksDetails(linkedBanks);
    setAllBanksModal((prev) => !prev);
  };

  const handleDisapproveClick = (msg: string) => {
    setDisapproveMsg(msg);
    setShowMsg(true);
  };

  return (
    <main className="overflow-x-auto w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <h1 className="text-base font-semibold ">Document Check</h1>
            <label htmlFor="filterOccupation">
              Sort by:
              <select
                name="filterOccupation"
                id="filterOccupation"
                className="border p-2 ml-4"
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="all">All</option>
                <option value="employed">Employed</option>
                <option value="self-employed">Self-employed</option>
                <option value="student">Students</option>
              </select>
            </label>
          </div>
          <section className="overflow-y-auto">
            <table className="w-full" style={{ minWidth: "1200px" }}>
              <thead className="bg-gray-50 text-left">
                <tr>
                  <th className="p-2">S/N</th>
                  <th className="p-2">Name</th>
                  <th className="p-2">Email</th>
                  <th className="p-2">BVN</th>
                  <th className="p-2">All Banks</th>
                  <th className="p-2">Occupation</th>
                  <th className="p-2">Action 1</th>
                  <th className="p-2">Action 2</th>
                  <th className="p-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {users.length === 0 ? (
                  <tr className="text-center">
                    <td colSpan={7} className="p-10 text-secondary">
                      No pending verification!
                    </td>
                  </tr>
                ) : (
                  users
                    .filter((user: any) => {
                      if (filter === "employed") {
                        return user.employmentStatus === "employed";
                      } else if (filter === "self-employed") {
                        return user.employmentStatus === "selfEmployed";
                      } else if (filter === "student") {
                        return user.employmentStatus === "student";
                      } else if (filter === "all") {
                        return user.employmentStatus;
                      }
                      return true;
                    })
                    .map((filteredUser: any, index) => {
                      const filteredJob =
                        filteredUser.employmentStatus === "employed"
                          ? "Employed"
                          : filteredUser.employmentStatus === "selfEmployed"
                          ? "Self-Employed"
                          : filteredUser.employmentStatus === "student"
                          ? "Student"
                          : null;

                      const filteredJobObject =
                        filteredUser.employmentStatus === "employed"
                          ? filteredUser.employed
                          : filteredUser.employmentStatus === "selfEmployed"
                          ? filteredUser.selfEmployed
                          : filteredUser.employmentStatus === "student"
                          ? filteredUser.student
                          : null;
                      return (
                        <tr className="border-b" key={index}>
                          <td className="text-secondary p-2">{index + 1}</td>
                          <td className="p-2">
                            {filteredUser.lastName} {filteredUser.firstName}
                          </td>
                          <td className="p-2">{filteredUser.email}</td>
                          <td className="p-2">
                            <button
                              onClick={() => handleBvnClick(filteredUser)}
                              className="text-blue-500 hover:underline focus:outline-none"
                            >
                              {filteredUser.bvn}
                            </button>
                          </td>
                          <td className="p-2">
                            <button
                              className="bg-secondary text-white text-sm rounded-md p-2"
                              onClick={() =>
                                handleViewAllBank(filteredUser._id, filteredUser.linkedBanks)
                              }
                            >
                              View
                            </button>
                          </td>
                          <td className="p-2">
                            <button
                              onClick={() =>
                                handleJobClick(filteredJobObject, filteredJob)
                              }
                              className="text-blue-500 hover:underline focus:outline-none"
                            >
                              {filteredJob}
                            </button>
                          </td>
                          <td className="p-2">
                            <button
                              onClick={() => {
                                setAction1((prev) => !prev);
                                setId(filteredUser._id);
                              }}
                              className="text-blue-500 hover:underline focus:outline-none"
                            >
                              Set Credit Limit
                            </button>
                          </td>
                          <td className="p-2">
                            <select
                              name="select"
                              id="select"
                              className="cursor-pointer"
                              onChange={(e) => {
                                handleAction(e);
                                setId(filteredUser._id);
                              }}
                              disabled={
                                filteredUser.adminVerification === "dstage2"
                              }
                            >
                              <option value="">Select an action</option>
                              <option value="approve">Approve</option>
                              <option value="disapprove">Disapprove</option>
                            </select>
                          </td>
                          <td className="p-2">
                            <button
                              onClick={
                                filteredUser.msg
                                  ? () =>
                                      handleDisapproveClick(filteredUser.msg)
                                  : undefined
                              }
                              className={
                                filteredUser.adminVerification === "dstage2"
                                  ? "text-red-600"
                                  : "text-yellow-400"
                              }
                            >
                              {filteredUser.adminVerification === "dstage2"
                                ? "Disapproved"
                                : "Pending"}
                            </button>
                          </td>
                        </tr>
                      );
                    })
                )}
              </tbody>
            </table>
          </section>
        </div>
      </div>
      {showMsg && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={() => setShowMsg(false)}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>{disapproveMsg}</p>
              <div>
                <button
                  type="button"
                  onClick={() => setShowMsg(false)}
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {action2 === "approve" && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={handleClearSelection}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>Are you sure you want approve this user?</p>
              <div>
                <button
                  type="button"
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                  onClick={approveCustomerHandler}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="w-20 px-5 py-3 bg-red-500 rounded text-white "
                  onClick={handleClearSelection}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {action2 === "disapprove" && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between items-center flex-col text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={handleClearSelection}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>Are you sure you want disapprove this user?</p>
              <label htmlFor="reason" className="text-left">
                Please state your reason
              </label>
              <textarea
                name="reason"
                id="reason"
                className="border my-4 text-sm p-2"
                placeholder="What is your reason?"
                value={disapproveMsg}
                onChange={(e) => setDisapproveMsg(e.target.value)}
                cols={30}
                rows={5}
                required
              ></textarea>
              <div>
                <button
                  type="button"
                  onClick={disaproveCustomerHandler}
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="w-20 px-5 py-3 bg-red-500 rounded text-white "
                  onClick={handleClearSelection}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {action1 && (
        <CreditLimitModal
          id={id}
          setId={setId}
          setAction1={setAction1}
          creditLimit={creditLimit}
          setCreditLimit={setCreditLimit}
          getAllUsers={getAllUsers}
        />
      )}
      {bvnModalOpen && (
        <BVNVerificationModal
          setBvnModalOpen={setBvnModalOpen}
          selectedBvn={selectedBvn}
        />
      )}

      {allBanksModal && (
        <AllBanksModal
          setAllBanksModal={setAllBanksModal}
          setAllBanksDetails={setAllBanksDetails}
          allBanksDetails={allBanksDetails}
          userId={userId}
          getAllUsers={getAllUsers}
          documentCheckPage={documentCheckPage}
          setDocumentCheckPage={setDocumentCheckPage}
        />
      )}

      {studentModalOpen && (
        <Student
          selectedInfo={selectedInfo}
          setStudentModalOpen={setStudentModalOpen}
        />
      )}
      {selfEmployedModalOpen && (
        <SelfEmployed
          setSelfEmployedModalOpen={setSelfEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
      {employedModalOpen && (
        <Employed
          setEmployedModalOpen={setEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
    </main>
  );
};
