import axios from "axios";
import { useState, useEffect } from "react";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import toast from "react-hot-toast";

export const Faq = () => {
  const [inputValues, setInputValues] = useState({
    title: "",
    content: "",
  });
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);
  useEffect(() => {
    getFaqs();
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const getFaqs = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getFaqs`,
        {
          headers: {
            Authorization: token,
          },
        }
      ); 
      setFaqs(res.data.faqs);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setFaqs([])
    }
  };

  const handleCreateFAQS = async() => {
    const payload = {
      title: inputValues.title,
      content : inputValues.content
    }
    console.log(payload)
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/createFaq`, payload, {
        headers: {
          Authorization : token
        }
      })
      console.log(res.data)
      toast.success("Faqs added successfully!")
      setInputValues({
        title: "",
        content: ""
      })
    } catch (error) {
      console.log(error)
      toast.error("Error adding faq, please try again later")
    }
  }

  return (
    <main className="w-full">
      <div className="bg-white p-4 rounded-md font-semibold">
        <h2>FAQ</h2>
      </div>
      <section className="bg-white p-5 mt-2 mb-5 rounded-md">
        <p className="font-medium">Add new FAQ</p>
        <div className="flex items-center justify-center gap-5 my-5">
          <div className="flex flex-col gap-5 w-[70%]">
            <div className="flex flex-col">
              <label className="pb-2">Question:</label>
              <input
                type="text"
                name="title"
                value={inputValues.title}
                onChange={handleInputChange}
                className="border p-2 rounded border-green-200"
              />
            </div>
            <div className="flex flex-col">
            <label className="pb-2">Answer:</label>
              <textarea
                className="border p-2 rounded border-green-200"
                name="content"
                id="newFaq"
                cols={60}
                rows={5}
                value={inputValues.content}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <button
              type="button"
              onClick={handleCreateFAQS}
              className="p-2 bg-[#008b50] w-1/3 self-end text-white rounded-md"
            >
              Add FAQ
            </button>
          </div>
        </div>
      </section>
      <section className="bg-white w-full rounded p-5">
        <table className={`w-full ${isLoading && "h-[40vh] animate-pulse"}`}>
          <thead className="text-left">
            <tr className="">
              <th className="py-3">SN</th>
              <th className="py-3">Question</th>
              <th className="py-3">Answer</th>
              <th className="w-32 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {faqs.map((faq: any, index) => (
              <tr key={index} className="border-t border-b ">
                <td className="p-2">{index + 1}</td>
                <td className="p-2">{faq.title}</td>
                <td className="p-2">{faq.content}</td>
                <td className="p-2">
                  <button
                    type="button"
                    className="border p-2 my-1 w-20 bg-yellow-300"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="border p-2 my-1 w-20 bg-red-600"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </main>
  );
};
