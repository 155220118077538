import { useEffect, useState } from "react";
import { OrderDetails } from "../../components/modals/OrderDetails";
import { useLocation } from "react-router-dom";
const data = [
  {
    name: "Ada Obi",
    items: [
      {
        name: "Ugu soup",
        quantity: 2,
      },
    ],
    totalAmount: 6000,
  },
  {
    name: "John Paul",
    items: [
      {
        name: "Nsala soup",
        quantity: 2,
      },
    ],
    totalAmount: 5000,
  },
  {
    name: "Mary Amaka",
    items: [
      {
        name: "Gbegiri soup",
        quantity: 2,
      },
    ],
    totalAmount: 3000,
  },
  {
    name: "Musa Ahmed",
    items: [
      {
        name: "Tuwo soup",
        quantity: 2,
      },
    ],
    totalAmount: 7000,
  },
];

export const Settlement = () => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [items, setItems] = useState<any>({});
  const [viewItems, setViewItems] = useState(false);

  const location = useLocation()

  useEffect(() => {
    const calcTotalItemAmount = () => {
      const total = data.reduce((acc, item) => acc + item.totalAmount, 0);
      setTotalAmount(total);
    };
    calcTotalItemAmount();
  }, []);

  const handleViewItems = (items: any) => {
    setItems(items);
    setViewItems((prev) => !prev);
  };

  return (
    <main>
      <h2 className="font-semibold text-lg">Settlement for {location.state.name}</h2>
      <section className="bg-white p-5 rounded-md mt-2">
        <h2 className="font-semibold mb-3 text-lg">Today's Order</h2>
        <table className="w-full text-left">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-4 pl-4">SN</th>
              <th className="py-4">Clients' Name</th>
              <th className="py-4">Items Purchased</th>
              <th className="py-4 pr-4">Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr className="border-b">
                <td className="py-4 pl-4">{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  <button
                    type="button"
                    className="text-sm p-2 bg-secondary text-white rounded-md"
                    onClick={() => handleViewItems(item)}
                  >
                    View
                  </button>
                </td>
                <td className="py-4 pr-4">₦{item.totalAmount}</td>
              </tr>
            ))}
            <tr className="font-semibold text-xl">
              <td colSpan={3} className="pt-3">
                Total Amount
              </td>
              <td>₦{totalAmount}</td>
            </tr>
          </tbody>
        </table>
        <div className="m-3 text-right">
          <button type="button" className="bg-secondary text-white text-sm p-2">
            Make Payment
          </button>
        </div>
      </section>
      {viewItems && (
        <OrderDetails
          setViewItems={setViewItems}
          viewItems={viewItems}
          orderDetails={items}
        />
      )}
    </main>
  );
};
