import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import SideBar from "../components/Sidebar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import LogoutModal from "../components/LogoutModal";

export const BasePage = () => {
  const [tabNavigation, setTabNavigation] = useState(false);
  const { lastName } = useAppSelector((store: RootState) => store.auth);
  const [role, setRole] = useState("")
  const [modals, setModals] = useState({
    logout: false,
  });
  useEffect(() => {
    getAllAdmins();
  }, []);

  const toggleTabNavigation = () => {
    setTabNavigation(!tabNavigation);
  };

  const openModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: true }));
  };

  const closeModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: false }));
  };

  const getAllAdmins = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-admins-except-superadmin`
      );
      const filteredAdmin = res.data.filter(
        (admin: any) => admin.lastName === lastName
      );
      console.log(filteredAdmin[0].adminRole);
      setRole(filteredAdmin[0].adminRole);
      console.log(res.data);
    } catch (error) {
    }
  };

  return (
    <div className="lg:flex">
      <SideBar
        tabNavigation={tabNavigation}
        toggleTabNavigation={toggleTabNavigation}
        openModal={() => openModal("logout")}
        role={role}
      />
      <div className="w-full ml-0 lg:ml-[20%] overflow-x-hidden">
        <Navbar
          tabNavigation={tabNavigation}
          toggleTabNavigation={toggleTabNavigation}
        />
        <div className="bg-[#F6F4F8] pt-[5rem] lg:pt-10 py-1 px-4 lg:px-6 2xl:px-8 pb-8">
            <Outlet />
        </div>
      </div>
      {modals.logout && <LogoutModal closeModal={()=>closeModal("logout")} />}
    </div>
  );
};
