import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

export const RiderActivity = ({ state }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [riderInfo, setRiderInfo] = useState([]);
  const [checkedStatus, setCheckedStatus] = useState("");
  const location = useLocation();
  const {id, firstName, lastName, email, status, image, phoneNo} = location.state || {}

  useEffect(() => {
    getRiderInfo();
    setCheckedStatus(status);
  }, []);

  const getRiderInfo = async () => {
    setIsLoading((prev) => !prev);
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getLogisticMilestone/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setRiderInfo(res.data);
      setIsLoading((prev) => !prev);
      return res.data;
    } catch (error: any) {
      toast(error.response);
      console.error(error.response.data);
    }
  };

  return (
    <main>
      <div>
        <h1 className="font-bold text-lg">Rider Activity</h1>
      </div>
      <section className="md:w-11/12 bg-white md:p-5 p-2 my-3 rounded-md mx-auto flex justify-between flex-col md:flex-row gap-5 md:gap-0">
        <div className="w-32 h-32">
          <img
            className="w-full h-full rounded-full"
            src={image}
            alt={lastName+ ' '+ firstName}
          />
        </div>
        <form action="" className="md:w-9/12">
          <div className="flex justify-between items-center mb-3">
            <label className="md:text-base text-sm font-medium" htmlFor="name">
              Full name:
            </label>
            <input
              className="border w-3/5 md:text-base text-sm p-2 rounded-md"
              type="text"
              name="name"
              id="name"
              disabled
              value={lastName + " " + firstName}
            />
          </div>
          <div className="flex justify-between items-center mb-3">
            <label className="md:text-base text-sm font-medium" htmlFor="email">
              Email:
            </label>
            <input
              className="border w-3/5 md:text-base text-sm p-2 rounded-md"
              type="email"
              name="email"
              id="email"
              disabled
              value={email}
            />
          </div>
          <div className="flex justify-between items-center mb-3">
            <label
              className="md:text-base text-sm font-medium"
              htmlFor="phoneNo"
            >
              Phone number:
            </label>
            <input
              className="border w-3/5 md:text-base text-sm p-2 rounded-md"
              type="text"
              name="phoneNo"
              id="phoneNo"
              disabled
              value={phoneNo}
            />
          </div>
          <div className="flex justify-between items-center mb-3">
            <label
              className="md:text-base text-sm font-medium"
              htmlFor="status"
            >
              Status:
            </label>
            <div className="border p-2 w-3/5 md:text-base text-sm rounded-md flex flex-col">
              <span className="flex items-center">
                <input
                  type="radio"
                  className={`mx-3 w-fit border appearance-none p-2 rounded-full ${
                    checkedStatus === "active"
                      ? "checked:bg-secondary"
                      : "checked:bg-gray-100"
                  }
                  `}
                  disabled
                  name="active"
                  id="active"
                  checked
                />
                <label htmlFor="onTheMove">On the move</label>
              </span>
              <span className="flex items-center">
                <input
                  type="radio"
                  className={`mx-3 border appearance-none p-2 rounded-full ${
                    checkedStatus === "idle"
                      ? "checked:bg-yellow-200"
                      : "checked:bg-gray-100"
                  }`}
                  disabled
                  name="idle"
                  id="idle"
                  checked
                />
                <label htmlFor="idle">Idle</label>
              </span>
              <span className="flex items-center">
                <input
                  type="radio"
                  className={`mx-3 border appearance-none p-2 rounded-full ${
                    checkedStatus === "unavailable"
                      ? "checked:bg-red-500"
                      : "checked:bg-gray-100"
                  }`}
                  disabled
                  name="unavailble"
                  id="unavailable"
                  checked
                />
                <label htmlFor="unavailable">Unavailable</label>
              </span>
            </div>
          </div>
        </form>
      </section>
        <div className="p-3 bg-white rounded-md">
          <h2 className="font-semibold">Milestone</h2>
        </div>
        <section className="overflow-x-auto">
        <table className="md:w-full w-[700px] my-3">
          <thead>
            <tr
              className={`bg-gray-50 font-bold md:text-base text-sm h-12 text-left ${
                isLoading && "animate-pulse "
              }`}
            >
              <th className="pl-3">Order made on</th>
              <th className="pl-3">Status</th>
              <th className="pl-3">Pick up</th>
              <th className="pl-3">Delivery address</th>
              <th className="pl-3">Client rating</th>
            </tr>
          </thead>
          <tbody className="md:text-base text-sm">
            {riderInfo.length > 0 ? (
              riderInfo.map((item: any, index) => (
                <tr key={index} className="cursor-default">
                  <td className="p-3 border-b">
                    {new Date(item.createdAt).toLocaleDateString()}
                  </td>
                  <td className="p-3 border-b">{item.status}</td>
                  <td className="p-3 border-b">{item.pickUp}</td>
                  <td className="p-3 border-b">{item.dropOff}</td>
                  <td className="p-3 border-b">{item.clientRating}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="p-5 bg-white text-center text-secondary">
                  No Milestone!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </main>
  );
};
