import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const login = createAsyncThunk(
  "login",
  async (credentials: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin-login`,
        credentials
      );
      sessionStorage.setItem("adminLevel", response.data.user.adminLevel);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getAllProducts = createAsyncThunk(
  "getAllProducts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-products`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getProduct = createAsyncThunk(
  "getProduct",
  async (productId: any, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-product/${productId}`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getAllProductType = createAsyncThunk(
  "getAllProductType",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllProductType`
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const updateSingleProduct = createAsyncThunk(
  "updateProduct",
  async ({ measurement, price, id }: any, thunkAPI) => {
    const auth: any = sessionStorage.getItem("authState");
    const jsonData = JSON.parse(auth);
    const token = jsonData.token;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/update-product/${id}`,
        { measurement, price },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteProductType = createAsyncThunk(
  "deleteProductType",
  async (categoryId: string, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/deleteProductType/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "deleteProduct",
  async (productId: string, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delete-product/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createProductItem = createAsyncThunk(
  "createProductItem",
  async (data: any, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/create-product`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);
export const createProductType = createAsyncThunk(
  "createProductType",
  async (data: any, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/createProductType`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const verificationStageOne = createAsyncThunk(
  "verificationStageOne",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/verification-stage-one`
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getOrders = createAsyncThunk("getOrders", async (_, thunkAPI) => {
  try {
    const auth: any = sessionStorage.getItem("authState");
    const jsonData = JSON.parse(auth);
    const token = jsonData.token;

    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/getAllOrdersByCustomer`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getSingleCustomer = createAsyncThunk(
  "getSingleCustomer",
  async (id: string, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getSingleCustomer/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllDirectDebitMandate = createAsyncThunk(
  "getAllDirectDebitMandate",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllDirectDebitMandate`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const allCustomerNextPay = createAsyncThunk(
  "allCustomerNextPay",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/allCustomerNextPay`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDefaulters = createAsyncThunk(
  "getDefaulters",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/defaulters`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const approveOrder = createAsyncThunk(
  "approveOrder",
  async (data: any, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/approve-order`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllBanner = createAsyncThunk(
  "getAllBanner",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllBanner`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return res.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "deleteBanner",
  async (id: string, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/deleteBanner/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllDispatch = createAsyncThunk(
  "getAllDispatch",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getAllDispatcherDetails`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return res.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const logisticDashboard = createAsyncThunk(
  "logisticDashboard",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/logisticDashboardStatistic`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return res.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const logisticAssignDetails = createAsyncThunk(
  "logisticAssignDetails",
  async (_, thunkAPI) => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getDetailsForLogisticAssignDelivery`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return res.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
