import AdminDetails from "../components/AdminDetails";
const Dashboard = () => {
  const role = "marketer";

  return (
    <div className="flex w-full">
      <div className="bg-[#F6F4F8] py-1 px-4 lg:px-6 2xl:px-8 pb-8 w-full">
        <AdminDetails />
        {role === "marketer" ? (
          <section className="flex items-start justify-between flex-col xl:flex-row w-full">
            {/* <div className="w-full xl:w-[65%]">
               <StageOneVerification
                 openModal={() => openModal("addCustomer")}
                 openSuccessModal={() => openModal("success")}
                 openProductModal={() => openModal("products")}
                 openCalendarModal={() => openModal("calendar")}
                 setProductsObj={setProductsObj}
                 setCustomerId={setCustomerId}
               />
             </div> */}
            <div className=" w-full xl:w-[33%] mt-6 xl:mt-0"></div>
          </section>
        ) : (
          <section className="flex items-start justify-between flex-col xl:flex-row w-full"></section>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
