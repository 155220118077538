import axios from "axios";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../components/elements/LoadingSpinner";

const PaymentHistory = ({ userId, token }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userPayDetails, setUserPayDetails] = useState<any>([]);

  useEffect(() => {
    getPaymentDetails();
  }, []);

  const getPaymentDetails = async () => {
    setIsLoading(true);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getRepaymentHistoryByCustomerId/${userId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(res.data);
      setIsLoading(false);
      setUserPayDetails(res.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <section className="bg-white p-5 rounded-md">
      <h3 className="font-bold underline mb-3">Payment History</h3>
      <div>
        <table className="w-full text-center">
          <thead className="bg-gray-100 rounded-md">
            <tr>
              <th className="p-3">Date Paid</th>
              <th>Amount Paid</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={2} className="p-5">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : userPayDetails.loanRecord &&
              userPayDetails.loanRecord.length > 0 ? (
              userPayDetails.loanRecord.map((item: any, index: any) => (
                <tr key={index}>
                  <td className="p-3 border-b">
                    {new Date(item.payDate).toLocaleDateString()}
                  </td>
                  <td className="border-b">₦{Math.round(item.paidLoan)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2} className="text-secondary text-center p-5">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default PaymentHistory;
