import { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import { getAllDirectDebitMandate } from "../redux/thunk";

export const Repayment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllDirectDebitMandate()).then((response: any) => {
      setData(response.payload);
      setIsLoading(false);
    });
  }, [dispatch]);

  return (
    <main className=" w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <div className="flex justify-between w-full">
              <h1 className="text-base font-semibold ">Repayment History</h1>
            </div>
          </div>
          <div className="w-full overflow-x-auto" style={{ minWidth: "700px" }}>
            <div className="bg-gray-50 flex font-bold p-4">
              <>
                <div className="flex-1 p-2 ">S/N</div>
                <div className="flex-1 p-2">Name</div>
                <div className="flex-1 p-2 ">Total Amount</div>
                <div className="flex-1 p-2">Balance</div>
                <div className="flex-1 p-2">Outstanding</div>
                <div className="flex-1 p-2">Start Date</div>
                <div className="flex-1 p-2">End Date</div>
              </>
            </div>
            <div className="px-4">
              {data.map((data: any, index: any) => (
                <div className="flex border-b border-gray-300 py-2" key={index}>
                  <div className="flex-1 text-secondary p-2">{index + 1}</div>
                  <div className="flex-1 p-2">{data.payer_name}</div>
                  <div className="flex-1 p-2">{data.amount_total}</div>
                  <div className="flex-1 p-2">{data.amount_balance}</div>
                  <div className="flex-1 p-2">{data.amount_outstanding}</div>
                  <div className="flex-1 p-2">
                    {new Date(data.start_date).toLocaleString()}
                  </div>
                  <div className="flex-1 p-2">
                    {new Date(data.end_date).toLocaleString()}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
