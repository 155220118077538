import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { verificationStageOne } from "../thunk";

interface VerificationState {
  employed: any[];
  selfEmployed: any[];
  student: any[];
  status: string;
  error: string;
}

const initialState: VerificationState = {
  employed: [],
  selfEmployed: [],
  student: [],
  status: "idle",
  error: "",
};

interface PayloadActionType {
  customers: any[];
}

const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verificationStageOne.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(
        verificationStageOne.fulfilled,
        (state, action: PayloadAction<PayloadActionType>) => {
          state.status = "success";
          const data = action.payload.customers;
          console.log("payload", data);

          // check if the id already exists in the array else push.
          data.forEach((item: any) => {
            if (
              item.employmentStatus === "employed" &&
              !state.employed.some((empItem) => empItem.id === item.id)
            ) {
              state.employed.push(item);
            } else if (
              item.employmentStatus === "selfEmployed" &&
              !state.selfEmployed.some((selfEmpItem) => selfEmpItem.id === item.id)
            ) {
              state.selfEmployed.push(item);
            } else if (
              item.employmentStatus === "student" &&
              !state.student.some((studItem) => studItem.id === item.id)
            ) {
              state.student.push(item);
            }
          });
          state.error = "nil";
        }
      )
      .addCase(verificationStageOne.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      });
  },
});

export default verificationSlice.reducer;
