import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { approveOrder, getOrders, getSingleCustomer } from "../../redux/thunk";
import toast from "react-hot-toast";
import LoadingSpinner from "../../components/elements/LoadingSpinner";

export const Order = () => {
  const [activeTab, setActiveTab] = useState("flexible");
  const [viewDetails, setViewDetails] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const [orderDetails, setOrderDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [pickUpDetails, setPickUpDetails] = useState("");
  const [filter, setFilter] = useState("");
  const [error, setError] = useState<any>({});

  const dispatch = useAppDispatch();
  const { orders } = useAppSelector((store) => store.orders);

  useEffect(() => {
    setIsLoading((prev) => !prev);
    dispatch(getOrders()).then(() => {
      setIsLoading((prev) => !prev);
    });
  }, []);

  useEffect(() => {
    const extractedUserIds = orders.map((item: any) => item.userId);

    const fetchUsersData = async () => {
      const usersData = await Promise.all(
        extractedUserIds.map(async (id: any) => {
          const response = await dispatch(getSingleCustomer(id));
          console.log(response.payload);

          return response.payload;
        })
      );
      setUsers(usersData);
    };

    if (extractedUserIds.length > 0) {
      fetchUsersData();
    }
  }, [dispatch, orders]);

  const validateOrderApproval = () => {
    let isValid = true;
    let newError: any = {};

    if (pickUpDetails.trim().length === 0) {
      newError.pickUpDetails = "Please enter pick up location";
      isValid = false;
    }
    setError(newError);
    return isValid;
  };

  const handleApproveOrder = async (id: string) => {
    if (!validateOrderApproval()) {
      return;
    }

    try {
      setIsLoading((prev) => !prev);
      const response: any = await dispatch(
        approveOrder({ orderId: id, pickUpDetails })
      );
      toast.success(response.message || "Order has been approved!");
      dispatch(getOrders());
      setIsLoading((prev) => !prev);
      setViewDetails((prev) => !prev);
    } catch (error: any) {
      toast.error(error.message || "Failed to approve order!");
    }
  };

  const handleViewDetails = (orderData: any) => {
    setOrderDetails(orderData);
    setViewDetails((prev) => !prev);
  };

  const filteredOrders = orders.filter((order: any) => {
    const user = users.find(
      (user: any) => user.customer && user.customer.id === order.userId
    );

    if (!user || !user.customer) {
      return false;
    }

    if (activeTab === "flexible") {
      return user.customer.accountType === "flexible";
    } else if (activeTab === "outright") {
      return user.customer.accountType === "outright";
    }

    return false;
  });

  return (
    <main>
      <h2 className="font-semibold text-lg">Clients' Orders</h2>
      <div className=" my-6">
        <span
          className={`cursor-pointer mr-5 pb-2 ${
            activeTab === "flexible" ? "border-b-2 border-secondary" : "null"
          }`}
          onClick={() => setActiveTab("flexible")}
        >
          Flexible Orders
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "outright" ? "border-b-2 border-secondary" : "null"
          }`}
          onClick={() => setActiveTab("outright")}
        >
          Outright Orders
        </span>
        <span className="float-right">
          <label htmlFor="filter">
            Sort:
            <select
              name="filter"
              id="filter"
              className="border p-2 ml-4 text-sm"
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="delivered">Delivered</option>
              <option value="rider">Rider</option>
            </select>
          </label>
        </span>
      </div>
      <section className="w-full bg-white p-3">
        <table className="w-full">
          <thead className="text-left">
            <tr>
              <th>SN</th>
              <th>Client</th>
              <th>Action</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="relative">
            {isLoading ? (
              <tr>
                <td colSpan={4} className="mx-auto">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : orders.length === 0 ? (
              <tr>
                <td className="flex items-end justify-end flex-col py-6">
                  <img
                    src="/assets/nothing.png"
                    className="w-[230px] h-[230px]"
                    alt=""
                  />
                </td>
              </tr>
            ) : (
              filteredOrders
              .filter((order: any) => {
                if (filter === "pending") {
                  return order.status === "pending";
                } else if (filter === "approved") {
                  return order.status === "approved";
                } else if (filter === "delivered") {
                  return order.status === "delivered";
                } else if (filter === "rider") {
                  return order.status === "rider";
                } else {
                  return order;
                }
              })
              .map((order: any, index: number) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {(() => {
                      const user = users.find(
                        (element: any) =>
                          element.customer &&
                          order.userId === element.customer.id
                      );

                      return (
                        <span>
                          {user.customer.firstName} {user.customer.lastName}
                        </span>
                      );
                    })()}
                  </td>
                  <td className="py-1">
                    <button
                      type="button"
                      className="p-2 bg-secondary rounded text-white text-sm"
                      onClick={() => handleViewDetails(order)}
                    >
                      View details
                    </button>
                  </td>
                  <td className="py-1">
                    <p className="p-2 w-fit bg-yellow-300 rounded text-white text-sm">
                      {order.status}
                    </p>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </section>

      {viewDetails && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 overflow-y-auto">
          <div
            className="bg-white p-4 rounded-md relative mx-auto my-10"
            style={{ width: "500px" }}
          >
            <div className="flex justify-between items-center  text-dark p-2 rounded-t-md">
              <h2 className="text-lg font-semibold">Order Details</h2>
              <button
                onClick={() => setViewDetails(false)}
                className="text-dark"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <hr />
            <div className="mt-8 mb-2">
              <table className="w-full mb-4">
                <thead className="text-left">
                  <tr className="leading-10">
                    <th>Products</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody className="text-sm">
                  {orderDetails.orderItems.map((item: any, index: any) => (
                    <tr key={index}>
                      <td className="border-b py-1">{item.name}</td>
                      <td className="border-b py-1">{item.quantity}</td>
                      <td className="border-b py-1">{item.totalPrice}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="text-sm leading-7">
                <p>Total: ₦{orderDetails.allItemsTotalPrice}</p>
                <p>Address: {orderDetails.deliveryDetails}</p>
                <p>Phone Number: </p>
              </div>
              <label htmlFor="pickUpDetails" className="text-sm">
                {orderDetails.status === "approved"
                  ? "Pickup Location:"
                  : "Add Pickup Location:"}
              </label>
              <textarea
                name="pickUpDetails"
                id="pickUpDetails"
                className="w-full mt-2 border-2 rounded-md text-sm p-2"
                cols={30}
                value={orderDetails.pickUpDetails || pickUpDetails}
                onChange={(e: any) => setPickUpDetails(e.target.value)}
                disabled={orderDetails.status !== "pending"}
              ></textarea>
              {error.pickUpDetails && (
                <p className="text-red-500 text-xs text-right">
                  {error.pickUpDetails}
                </p>
              )}
            </div>
            <hr />
            {orderDetails.status !== "pending" ? null : (
              <div className="p-2 flex justify-end gap-3">
                <button
                  className="p-2 rounded-md text-sm text-white bg-secondary w-20"
                  onClick={() => handleApproveOrder(orderDetails._id)}
                >
                  {isLoading ? <LoadingSpinner /> : "Approve"}
                </button>
                <button className="p-2 rounded-md text-sm text-white bg-red-500">
                  Decline
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </main>
  );
};
