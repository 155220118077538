import axios from "axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";

export const Assignment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("All");
  const [admins, setAdmins] = useState([]);
  const auth: any = sessionStorage.getItem("authState");
  const jsonData = JSON.parse(auth);
  const token = jsonData.token;

  useEffect(() => {
    getAllAdmins();
  }, []);

  const getAllAdmins = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-admins-except-superadmin`
      );
      setIsLoading(false);
      console.log(res.data);
      setAdmins(res.data);
    } catch (error) {
      console.log(error);
      setAdmins([]);
      setIsLoading(false);
    }
  };

  const addRoleHandler = async ({
    id,
    roleValue,
  }: {
    id: string;
    roleValue: string;
  }) => {
    const payload = {
      staffId: id,
      role: roleValue,
    };
    console.log(payload);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/addAdminRole`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(res.data);
      getAllAdmins();
      toast.success(res.data.message);
    } catch (error) {
      console.error(error);
      toast.error("Unable to add admin role!");
    }
  };

  const removeRoleHandler = async (adminID: string, roleId: string) => {
    console.log(
      `${process.env.REACT_APP_API_BASE_URL}/delete-admin-role/${adminID}/${roleId}`
    );
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delete-admin-role/${adminID}/${roleId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(res.data);
      toast.success(res.data.message);
      getAllAdmins();
    } catch (error) {
      console.error(error);
      toast.error("Unable to remove admin role!");
    }
  };

  return (
    <main className="w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`overflow-x-auto ${isLoading && "animate-pulse h-[50vh]"}`}
        >
          <div className="flex items-center justify-between p-6">
            <h1 className="text-base font-semibold">Assignment</h1>
            <div className="flex items-center">
              {/* <button className="bg-secondary mr-6 text-white shadow-md rounded-md px-4 py-2 text-sm border hover:border-secondary hover:text-secondary">
                Save Changes
              </button> */}
              <label htmlFor="filterAdmin">
                Sort by:
                <select
                  name="filterAdmin"
                  id="filterAdmin"
                  className="border p-2 ml-4"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="ssa">SSA</option>
                  <option value="admin">Admin</option>
                </select>
              </label>
            </div>
          </div>
          <section className="w-full p-5 overflow-x-auto">
            <table
              className={`p-5 text-center w-[1200px] ${
                isLoading && "animate-pulse h-[60vh]"
              }`}
            >
              <thead className="bg-gray-50 font-bold h-12">
                <tr className="p-5">
                  <th className="">S/N</th>
                  <th className="">Name</th>
                  <th className="text-sm">E-Verification 1</th>
                  <th className="text-sm">SE-Verification 1</th>
                  <th className="text-sm">S-Verification 1</th>
                  {/* <th className="text-sm">E-Verification 2</th>
                  <th className="text-sm">SE-Verification 2</th>
                  <th className="text-sm">S-Verification 2</th> */}
                  <th className="text-sm">Document Check</th>
                  <th className="text-sm">News Update</th>
                  <th className="text-sm">Logistics</th>
                  <th className="text-sm">Orders</th>
                  <th className="text-sm">Restaurant</th>
                  <th className="text-sm">Customer Care</th>
                </tr>
              </thead>
              <tbody className="px-4">
                {admins && admins.length > 0 ? (
                  admins
                    .filter((admin: any) => {
                      if (filter === "all") {
                        return admin;
                      } else if (filter === "ssa") {
                        return admin.adminLevel === "semi-superadmin";
                      } else if (filter === "admin") {
                        return admin.adminLevel === "admin";
                      }
                      return true;
                    })
                    .map((filteredAdmin: any, index) => {
                      const handleCheck = (value: string) => {
                        return filteredAdmin.adminRole.some(
                          (role: any) => role.role === value
                        );
                      };

                      return (
                        <tr
                          className="border-b border-gray-300 py-3"
                          key={index}
                        >
                          <td className="text-secondary p-2 font-semibold">
                            {index + 1}
                          </td>
                          <td className="p-2">
                            {filteredAdmin.lastName} {filteredAdmin.firstName}
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("E-Verification 1")}
                              onChange={() => {
                                if (handleCheck("E-Verification 1")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "E-Verification 1"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "E-Verification 1",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("SE-Verification 1")}
                              onChange={() => {
                                if (handleCheck("SE-Verification 1")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "SE-Verification 1"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "SE-Verification 1",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("S-Verification 1")}
                              onChange={() => {
                                if (handleCheck("S-Verification 1")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "S-Verification 1"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "S-Verification 1",
                                  });
                                }
                              }}
                            />
                          </td>
                          {/* <td className="p-2">
                          <input
                            type="checkbox"
                            name="checkbox"
                            id="checkbox"
                            checked={handleCheck("E-Verification 2")}
                            onChange={() => {
                              if (handleCheck("E-Verification 2")) {
                                const roleId = filteredAdmin.adminRole.find(
                                  (role: any) =>
                                    role.role === "E-Verification 2"
                                )._id;
                                removeRoleHandler(filteredAdmin.id, roleId);
                              } else {
                                addRoleHandler({
                                  id: filteredAdmin.id,
                                  roleValue: "E-Verification 2",
                                });
                              }
                            }}
                          />
                        </td>
                        <td className="p-2">
                          <input
                            type="checkbox"
                            name="checkbox"
                            id="checkbox"
                            checked={handleCheck("SE-Verification 2")}
                            onChange={() => {
                              if (handleCheck("SE-Verification 2")) {
                                const roleId = filteredAdmin.adminRole.find(
                                  (role: any) =>
                                    role.role === "SE-Verification 2"
                                )._id;
                                removeRoleHandler(filteredAdmin.id, roleId);
                              } else {
                                addRoleHandler({
                                  id: filteredAdmin.id,
                                  roleValue: "SE-Verification 2",
                                });
                              }
                            }}
                          />
                        </td>
                        <td className="p-2">
                          <input
                            type="checkbox"
                            name="checkbox"
                            id="checkbox"
                            checked={handleCheck("S-Verification 2")}
                            onChange={() => {
                              if (handleCheck("S-Verification 2")) {
                                const roleId = filteredAdmin.adminRole.find(
                                  (role: any) =>
                                    role.role === "S-Verification 2"
                                )._id;
                                removeRoleHandler(filteredAdmin.id, roleId);
                              } else {
                                addRoleHandler({
                                  id: filteredAdmin.id,
                                  roleValue: "S-Verification 2",
                                });
                              }
                            }}
                          />
                        </td> */}
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Document Check")}
                              onChange={() => {
                                if (handleCheck("Document Check")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) =>
                                      role.role === "Document Check"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Document Check",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("News Update")}
                              onChange={() => {
                                if (handleCheck("News Update")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "News Update"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "News Update",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Logistics")}
                              onChange={() => {
                                if (handleCheck("Logistics")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "Logistics"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Logistics",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Orders")}
                              onChange={() => {
                                if (handleCheck("Orders")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "Orders"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Orders",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Restaurant")}
                              onChange={() => {
                                if (handleCheck("Restaurant")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "Restaurant"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Restaurant",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Customer Care")}
                              onChange={() => {
                                if (handleCheck("Customer Care")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "Customer Care"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Customer Care",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td className="p-2">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              checked={handleCheck("Institute Upload")}
                              onChange={() => {
                                if (handleCheck("Institute Upload")) {
                                  const roleId = filteredAdmin.adminRole.find(
                                    (role: any) => role.role === "Institute Upload"
                                  )._id;
                                  removeRoleHandler(filteredAdmin.id, roleId);
                                } else {
                                  addRoleHandler({
                                    id: filteredAdmin.id,
                                    roleValue: "Institute Upload",
                                  });
                                }
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td className="text-secondary text-sm">No Admins Found!</td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </main>
  );
};
