import { useState } from "react";

export const CreditScore = () => {
  const [filter, setFilter] = useState("employed");
  const [isLoading] = useState(false);

  const placeholderData = [
    {
      id: 1,
      name: "John Doe",
      email: "john@example.com",
      creditScore: 30000,
      occupation: "employed",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane@example.com",
      creditScore: 30000,
      occupation: "employed",
    },
    {
      id: 3,
      name: "Jane Smith",
      email: "jane@example.com",
      creditScore: 30000,
      occupation: "self-employed",
    },
    {
      id: 4,
      name: "Jane Smith",
      email: "jane@example.com",
      creditScore: 30000,
      occupation: "self-employed",
    },
    {
      id: 5,
      name: "Jane Smith",
      email: "jane@example.com",
      creditScore: 30000,
      occupation: "student",
    },
    {
      id: 6,
      name: "Jane Smith",
      email: "jane@example.com",
      creditScore: 30000,
      occupation: "student",
    },
    {
      id: 7,
      name: "Jane Smith",
      email: "jane@example.com",
      creditScore: 30000,
      occupation: "student",
    },
  ];

  return (
    <main className="overflow-x-auto w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <div className="flex justify-between w-full">
              <h1 className="text-base font-semibold ">User Details</h1>
              <label htmlFor="filter">
                Sort:
                <select
                  name="filter"
                  id="filter"
                  className="border p-2 ml-4"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="employed">Employed</option>
                  <option value="self-employed">Self-employed</option>
                  <option value="student">Students</option>
                </select>
              </label>
            </div>
          </div>
          <div className="w-full" style={{ minWidth: "700px" }}>
            <div className="bg-gray-50 flex font-bold p-4">
              <div className="flex-1 p-2 ">S/N</div>
              <div className="flex-1 p-2 ">Name</div>
              <div className="flex-1  p-2">Email</div>
              <div className="flex-1 p-2">Credit Score</div>
            </div>
            <div className="px-4">
              {placeholderData
                .filter((data) => {
                  if (filter === "employed") {
                    return data.occupation === "employed";
                  } else if (filter === "self-employed") {
                    return data.occupation === "self-employed";
                  } else if (filter === "student") {
                    return data.occupation === "student";
                  }
                  return true;
                })
                .map((filteredData, index) => (
                  <div
                    className="flex border-b border-gray-300 py-2"
                    key={index}
                  >
                    <div className="flex-1 text-secondary p-2">{index+1}</div>
                    <div className="flex-1 p-2">{filteredData.name}</div>
                    <div className="flex-1 p-2">{filteredData.email}</div>
                    <div className="flex-1 p-2">{filteredData.creditScore}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
