import { useEffect, useState } from "react";
import FailedOrderModal from "../../components/modals/FailedOrderModal";
import axios from "axios";
import { DeliveryOrder } from "../../components/modals/DeliveryOrder";

export const AllDeliveries = () => {
  const [failedReason, setFailedReason] = useState("");
  const [orderDetails, setOrderDetails] = useState<Array<[]>>([]);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [showFailedOrderModal, setShowFailedOrderModal] = useState(false);
  const [allDeliveries, setAllDeliveries] = useState<Array<[]>>([]);

  const fetchDeliveries = async () => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/logisticAllDeliveries`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setAllDeliveries(res.data.requests[0].orderDetails);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchDeliveries();
  }, []);

  const handleFailedModal = (status: string, reason: any) => {
    setFailedReason(reason);
    if (status === "failed") {
      setShowFailedOrderModal((prev) => !prev);
    }
  };

  const handleOrderDetails = (orderItems: Array<[]>) => {
    setShowOrderDetails((prev) => !prev);
    setOrderDetails(orderItems);
  };

  return (
    <main>
      <div className="bg-white p-3 rounded-md my-3 flex justify-between">
        <h1 className="font-bold text-lg">All Deliveries</h1>
      </div>
      <section className="overflow-x-auto">
        <table className="md:w-full w-[700px] md:text-base text-sm text-left">
          <thead>
            <tr className="bg-gray-50 h-12">
              <th className="pl-2">SN</th>
              <th className="pl-2">Client name</th>
              <th className="pl-2">Assigned rider</th>
              <th className="pl-2">Delivery date</th>
              <th className="pl-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {allDeliveries.length > 0 ? (
              allDeliveries.map((item: any, index: any) => (
                <tr key={index} className="border-b-2">
                  <td className="p-3">{index + 1}</td>
                  <td
                    className="p-3 cursor-pointer hover:text-secondary hover:underline"
                    onClick={() => {
                      handleOrderDetails(item);
                    }}
                  >
                    {item.customerLastName + " " + item.customerFirstName}
                  </td>
                  <td className="p-3">
                    {item.riderLastName + " " + item.riderFirstName}
                  </td>
                  <td className="p-3">
                    {new Date(item.deliveryDate).toLocaleDateString()}
                  </td>
                  <td className="p-3">
                    <span
                      className={`p-1 md:text-sm text-xs rounded-md text-white ${
                        item.status === "completed"
                          ? "bg-secondary"
                          : item.status === "pending"
                          ? "bg-yellow-300"
                          : item.status === "ongoing"
                          ? "bg-yellow-600"
                          : "bg-red-500 cursor-pointer hover:bg-red-700"
                      }`}
                      onClick={() =>
                        item.status === "cancelled" &&
                        handleFailedModal(item.status, item.reason)
                      }
                    >
                      {item.status}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="p-3 text-secondary text-center">
                  No accepted deliveries!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>

      {showOrderDetails && (
        <DeliveryOrder
          showOrderDetails={showOrderDetails}
          setShowOrderDetails={setShowOrderDetails}
          orderDetails={orderDetails}
        />
      )}

      {showFailedOrderModal && (
        <FailedOrderModal
          setShowFailedOrderModal={setShowFailedOrderModal}
          reason={failedReason}
        />
      )}
    </main>
  );
};
