import React from "react";

const Student = ({ selectedInfo, setStudentModalOpen }: any) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">EDUCATION INFORMATION </h2>
          <button
            onClick={() => setStudentModalOpen(false)}
            className="text-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className=" border-b border-gray-300 mb-10 flex justify-between">
          <h4 className="font-semibold">Name of Institute:</h4>{" "}
          <h5>{selectedInfo.schoolName}</h5>
        </div>
        <div className="border-b border-gray-300 mb-10 flex justify-between">
          <h4 className="font-semibold">Name of Faculty:</h4>{" "}
          <h5>{selectedInfo.facultyName}</h5>
        </div>
        <div className="border-b border-gray-300 mb-10 flex justify-between">
          <h4 className="font-semibold">Name of Department:</h4>{" "}
          <h5>{selectedInfo.departmentName}</h5>
        </div>
        <div className="border-b border-gray-300 mb-10 flex justify-between">
          <h4 className="font-semibold">Matriculation Number</h4>{" "}
          <h5>{selectedInfo.matricNumber}</h5>
        </div>
        <div className="border-b border-gray-300 mb-10 flex justify-between">
          <h4 className="font-semibold">Level</h4> <h5>{selectedInfo.level}</h5>
        </div>
        <div className="border-b border-gray-300 mb-10 flex justify-between">
          <h4 className="font-semibold">ID Card Front</h4>
          <a
            className="w-1/2 truncate"
            href={selectedInfo.studentIdCard[0]}
            target="blank"
          >
            {selectedInfo.studentIdCard[0]}
          </a>
        </div>
        <div className="border-b border-gray-300 mb-10 flex justify-between">
          <h4 className="font-semibold">ID Card Back</h4>
          <a
            className="w-1/2 truncate"
            href={selectedInfo.studentIdCard[1]}
            target="blank"
          >
            {selectedInfo.studentIdCard[1]}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Student;
