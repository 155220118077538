import { useState, FormEvent } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import LoadingSpinner from "../components/elements/LoadingSpinner";

interface Errors {
  firstName?: string;
  lastName?: string;
  staffId?: string;
  email?: string;
  role?: string;
  confirmPassword?: string;
  password?: string;
}

export const CreateAdmin = () => {
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    staffId: "",
    role: "",
    password: "",
    confirmPassword: "",
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors: Errors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputValue.email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(inputValue.password)) {
      newErrors.password =
        "Password must be at least 8 characters and contain both letters and numbers";
      isValid = false;
    }

    if (inputValue.password !== inputValue.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    if (!inputValue.firstName) {
      newErrors.firstName = "Please enter your first name";
      isValid = false;
    }

    if (!inputValue.lastName) {
      newErrors.lastName = "Please enter your last name";
      isValid = false;
    }

    if (!inputValue.staffId) {
      newErrors.staffId = "Please enter your staff ID";
      isValid = false;
    }

    if (!inputValue.role) {
      newErrors.role = "Please enter the role of the new admin";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (value.trim() !== "") {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreateAccount = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      const payload = {
        firstName: inputValue.firstName,
        lastName: inputValue.lastName,
        staffId: inputValue.staffId,
        role: inputValue.role,
        email: inputValue.email,
        password: inputValue.password,
      };
      console.log(payload)

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/create-admin`,
          payload
        );
        toast.success(response.data.message);
        console.log(response.data)
        setIsLoading(false);
        setInputValue({
          firstName : "",
          lastName: "",
          email: "",
          staffId: "",
          role: "",
          password: "",
          confirmPassword: ""
        })
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error.response.data.message)
        console.error("An error occurred during registration:", error);
      }
    }
  };


  return (
    <main className=" w-full">
      <div className="bg-white rounded-md shadow-md p-6">
        <h1 className="text-lg font-semibold">Create Admin</h1>
        <form action="" className="w-full " onSubmit={handleCreateAccount}>
          <div className="flex justify-between my-3">
            <label htmlFor="name">First Name:</label>
            <input
              className="border p-2 w-4/5 focus:outline-[#008b50] rounded-md"
              type="text"
              id="firstName"
              name="firstName"
              value={inputValue.firstName}
              onChange={handleInputChange}
            />
            
          </div>
          {errors.firstName && (
              <p className="text-red-500 text-xs text-right">{errors.firstName}</p>
            )}
          <div className="flex justify-between my-3 ">
            <label htmlFor="name">Last Name:</label>
            <input
              className="border p-2 w-4/5 focus:outline-[#008b50] rounded-md"
              type="text"
              id="lastName"
              name="lastName"
              value={inputValue.lastName}
              onChange={handleInputChange}
            />
          </div>
          {errors.lastName && (
              <p className="text-red-500 text-xs text-right">{errors.lastName}</p>
            )}
          <div className="flex justify-between my-3">
            <label htmlFor="email">Email:</label>
            <input
              className="border p-2 w-4/5 focus:outline-[#008b50] rounded-md"
              type="email"
              id="email"
              name="email"
              value={inputValue.email}
              onChange={handleInputChange}
            />
          </div>
          {errors.email && (
              <p className="text-red-500 text-xs text-right">{errors.email}</p>
            )}
          <div className="flex justify-between my-3">
            <label htmlFor="position">Role:</label>
            <select
              className="w-1/2 border p-2 rounded-md focus:outline-[#008b50] text-sm"
              name="role"
              id="role"
              value={inputValue.role}
              onChange={handleInputChange}
            >
              <option value="">Select a position</option>
              <option value="semi-superadmin">SSA</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          {errors.role && (
              <p className="text-red-500 text-xs text-right">{errors.role}</p>
            )}
          <div className="flex justify-between my-3 ">
            <label htmlFor="email">Staff ID:</label>
            <input
              className="border p-2 w-4/5 focus:outline-[#008b50] rounded-md"
              type="text"
              id="staffId"
              name="staffId"
              value={inputValue.staffId}
              onChange={handleInputChange}
            />
          </div>
          {errors.staffId && (
              <p className="text-red-500 text-xs text-right">{errors.staffId}</p>
            )}
          <div className="flex justify-between my-3">
            <label htmlFor="email">Password:</label>
            <input
              className="border p-2 w-4/5 focus:outline-[#008b50] rounded-md"
              type="password"
              id="password"
              name="password"
              value={inputValue.password}
              onChange={handleInputChange}
            />
          </div>
          {errors.password && (
              <p className="text-red-500 text-xs text-right">{errors.password}</p>
            )}
          <div className="flex justify-between my-3">
            <label htmlFor="email">Confirm Password:</label>
            <input
              className="border p-2 w-4/5 focus:outline-[#008b50] rounded-md"
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={inputValue.confirmPassword}
              onChange={handleInputChange}
            />
          </div>
          {errors.confirmPassword && (
              <p className="text-red-500 text-xs text-right">{errors.confirmPassword}</p>
            )}
          <div className="w-full flex justify-center">
            <button  type="submit"
              className="bg-[#008b50] p-2 w-1/3 rounded-md text-white my-5 cursor-pointer">{isLoading ? <LoadingSpinner /> : 'Create'}</button>
          </div>
        </form>
      </div>
    </main>
  );
};
