import { useEffect, useState } from "react";
import { BusyRiderModal } from "../../components/modals/BusyRiderModal";
import axios from "axios";

const placeholderData = [
  {
    name: "Admed Kadiri",
    phoneNo: "0913234247558",
    client: "Sandra Obi",
    details: {
      orderDate: "12/05/2322",
      pickUpLocation: "Volks",
      pickedUp: true,
      deliveryAddress: "Festac",
      items: [
        {
          name: "Yam Tuber",
          itemQty: "2",
          price: "6000",
        },
        {
          name: "Yam Tuber",
          itemQty: "2",
          price: "6000",
        },
        {
          name: "Yam Tuber",
          itemQty: "2",
          price: "6000",
        },
      ],
    },
  },
  {
    name: "Amos Okoro",
    phoneNo: "091325758849",
    client: "John Ebuka",
    details: {
      orderDate: "12/05/2322",
      pickUpLocation: "Indomie hotspot",
      pickedUp: true,
      deliveryAddress: "Festac",
      itemName: "Indomie jollof",
      itemQty: "2",
      price: "2500",
    },
  },
  {
    name: "Dan Wilson",
    phoneNo: "091549384757",
    client: "Rita Anderson",
    details: {
      orderDate: "12/05/2322",
      pickUpLocation: "Volks",
      pickedUp: false,
      deliveryAddress: "Festac",
      itemName: "Hot spicy boli, ponmo garnished",
      itemQty: "4",
      price: "6000",
    },
  },
];

export const BusyRiders = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [busyRiders, setBusyRiders] = useState<any>([]);

  const fetchBusyRiders = async () => {
    try {
      const auth: any = sessionStorage.getItem("authState");
      const jsonData = JSON.parse(auth);
      const token = jsonData.token;

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/logisticBusyRider`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setBusyRiders(res.data.orders);
    } catch (error: any) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    fetchBusyRiders();
  }, []);

  return (
    <main>
      <div className="bg-white p-3 mt-3 rounded-md">
        <h1 className="font-bold text-lg">Busy Riders</h1>
      </div>
      <section className="overflow-x-auto">
        <table className="md:w-full w-[700px] my-3">
          <thead>
            <tr className="bg-gray-50 font-bold md:text-base text-sm h-12 text-center">
              <th>SN</th>
              <th>Rider's name</th>
              <th>Rider's number</th>
              <th>Client name</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="text-center md:text-base text-sm">
            {busyRiders.length > 0 ? (
              busyRiders.map((item: any, index: number) => (
                <tr key={index} className="cursor-default">
                  <td>{index + 1}</td>
                  <td className="p-3 border-b">{item.riderFullName}</td>
                  <td className="p-3 border-b">{item.riderPhoneNumber}</td>
                  <td className="p-3 border-b">
                    {item.customerLastName + " " + item.customerFirstName}
                  </td>
                  <td>
                    <button
                      type="button"
                      className="p-2 md:text-sm text-xs hover:bg-green-800 bg-secondary rounded-md text-white"
                      onClick={() => setShowDetails((prev) => !prev)
                      }
                    >
                      View more
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="p-5 bg-white text-secondary">
                  No Busy Riders!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      {showDetails && (
        <BusyRiderModal
          setShowDetails={setShowDetails}
          orderDetails={busyRiders}
        />
      )}
    </main>
  );
};
