import React from 'react'

const SelfEmployed = ({selectedInfo, setSelfEmployedModalOpen} : any) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
    <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
      <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
        <h2 className="text-lg font-semibold">
          Self-Employed Information{" "}
        </h2>
        <button
          onClick={() => setSelfEmployedModalOpen(false)}
          className="text-dark"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className=" border-b border-gray-300 mb-10 flex justify-between">
        <h4 className="font-semibold capitalize">
          business account bank name:
        </h4>{" "}
        <h5>{selectedInfo.businessAccountBankName}</h5>
      </div>
      <div className="border-b border-gray-300 mb-10 flex justify-between">
        <h4 className="font-semibold capitalize">
          business Account Name:
        </h4>{" "}
        <h5>{selectedInfo.businessAccountName}</h5>
      </div>
      <div className="border-b border-gray-300 mb-10 flex justify-between">
        <h4 className="font-semibold capitalize">
          business Account Number:
        </h4>{" "}
        <h5>{selectedInfo.businessAccountNumber}</h5>
      </div>
      <div className="border-b border-gray-300 mb-10 flex justify-between">
        <h4 className="font-semibold capitalize">business Name</h4>{" "}
        <h5>{selectedInfo.businessName}</h5>
      </div>
      <div className="border-b border-gray-300 mb-10 flex justify-between">
        <h4 className="font-semibold capitalize">Industry type</h4>{" "}
        <h5>{selectedInfo.industryType}</h5>
      </div>
      <div className="border-b border-gray-300 mb-10 flex justify-between">
        <h4 className="font-semibold capitalize">location</h4>{" "}
        <h5>{selectedInfo.location} </h5>
      </div>
      <div className="border-b border-gray-300 mb-10 flex justify-between">
        <h4 className="font-semibold capitalize">number Of Employees</h4>{" "}
        <h5>{selectedInfo.numberOfEmployees}</h5>
      </div>
    </div>
  </div>  )
}

export default SelfEmployed