import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { login } from "../thunk";
import toast from "react-hot-toast";

interface AuthState {
  isLoggedIn: boolean;
  token: string | null;
  uid: string | null;
  status: string;
  adminLevel: string | null;
  error: string;
  firstName: string | null;
  lastName: string | null;
  staffId: string | null;
}

const storedAuthState = sessionStorage.getItem('authState');
const initialState: AuthState = storedAuthState ? JSON.parse(storedAuthState) : {
  isLoggedIn: false,
  token: null,
  uid: null,
  status: 'idle',
  adminLevel: null,
  error: '',
  firstName: null,
  lastName: null,
  staffId: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.uid = null;
      state.adminLevel = null;
      sessionStorage.clear()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.error = '';
        state.adminLevel = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoggedIn = true;
        state.token = action.payload.token;
        state.uid = action.payload.user._id;
        state.status = "success";
        state.error = '';
        state.adminLevel = action.payload.user.adminLevel;
        state.firstName = action.payload.user.firstName;
        state.lastName = action.payload.user.lastName;
        state.staffId = action.payload.user.staffId;
        sessionStorage.setItem('authState', JSON.stringify(state));  
        console.log(action.payload)
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload ? action.payload.toString() : "Unknown error";
        toast.error("Invalid Credentials")
      })
  }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
