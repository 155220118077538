const OutrightCustomers = ({ outright, isLoading }: any) => {
  return (
    <div className="bg-white rounded-md shadow-md pb-6">
      <div
        className={`w-full overflow-x-auto ${
          isLoading && "animate-pulse h-[50vh]"
        }`}
      >
        <div
          className="flex items-center justify-between p-6"
          style={{ minWidth: "700px" }}
        >
          <div className="flex justify-between w-full">
            <h1 className="text-base font-semibold ">Outright Customers</h1>
          </div>
        </div>
        <section className="overflow-x-auto">
          <table className="w-[1020px]" style={{ minWidth: "700px" }}>
            <thead className="bg-gray-50 font-bold p-4 text-left">
              <tr>
                <th className="p-2 ">S/N</th>
                <th className="p-2 ">Name</th>
                <th className="p-2">Email</th>
                <th className="p-2 ">Address</th>
                <th className="p-2">Verification stage</th>
              </tr>
            </thead>
            <tbody className="px-4">
              {outright.map((data: any, index: number) => {
                return (
                  <tr
                    className="border-b border-gray-300 py-2"
                    key={index}
                  >
                    <td className="text-secondary p-2">{index + 1}</td>
                    <td className="p-2">
                      {data.firstName ? data.lastName : null}{" "}
                      {data.lastName ? data.firstName : null}
                    </td>
                    <td className="p-2">{data.email}</td>
                    <td className="p-2">
                      {data.deliveryAddress ? data.deliveryAddress : null}
                    </td>
                    <td className="p-2">{data.isComplete}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
};

export default OutrightCustomers;
