import { LogoutModalProps } from "../types/types";
import Button from "./elements/Button";
import Modal, { ModalContent } from "./elements/Modal";
import { TbLogout2 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { logout } from "../redux/auth/authSlice";
import toast from "react-hot-toast";

const LogoutModal = ({ closeModal }: LogoutModalProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logoutHandler = () => {
    try {
      dispatch(logout());
      navigate("/");
      toast.success("Logged out sucessfully!");
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 p-6 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <div className="bg-[#FCEBEA] flex items-center justify-center text-red-500 h-16 w-16 rounded-[50%]">
          <TbLogout2 className="w-8 h-8" />
        </div>
        <h1 className="font-semibold text-center pt-6 text-lg">
          You want to logout?
        </h1>
        <p className="text-gray-500 py-3 text-center text-sm w-[90%]">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit nisi
          labore minus modi dolore consequatur!
        </p>
        <div className="flex items-center justify-between w-full mt-2">
          <Button
            onClick={closeModal}
            className="border text-gray-500 hover:text-black hover:border-black  py-3 px-8"
          >
            Cancel
          </Button>
          <Button
            onClick={logoutHandler}
            className="bg-red-600 text-white py-3 px-8 hover:bg-red-500"
          >
            Logout
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default LogoutModal;
