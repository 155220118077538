import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Signup from "./pages/Authentication/Signup";
import Dashboard from "./pages/Dashboard";
import AdminSignup from "./pages/Authentication/Admin/AdminSignup";
import { SelfEmployedVerificationStage1 } from "./pages/VerificationStage1/SelfEmployedVerificationStage1";
import { StudentVerification } from "./pages/VerificationStage1/StudentVerification";
import { Assignment } from "./pages/Assignment";
import { DocumentCheck } from "./pages/DocumentCheck";
import { CreditScore } from "./pages/CreditScore";
import { BasePage } from "./pages/BasePage";
import StageOneVerification from "./pages/VerificationStage1/StageOneTable";
import { NextPay } from "./pages/NextPay";
import { Defaulters } from "./pages/Defaulters";
import { NewsUpdate } from "./pages/NewsUpdate";
import { CreateAdmin } from "./pages/CreateAdmin";
import { Store } from "./pages/Store";
import { Faq } from "./pages/Faq";
import Login from "./pages/Authentication/Login";
import Users from "./pages/Users/Users";
import { Order } from "./pages/Orders/Order";
import { Repayment } from "./pages/Repayment";
import { Logistics } from "./pages/Logistics/Logistics";
import { RiderActivity } from "./pages/Logistics/RiderActivity";
import { BusyRiders } from "./pages/Logistics/BusyRiders";
import { AssignDelivery } from "./pages/Logistics/AssignDelivery";
import { AssignedOrders } from "./pages/Logistics/AssignedOrders";
import { AllDeliveries } from "./pages/Logistics/AllDeliveries";
import { Disbutes } from "./pages/Logistics/Disbutes";
import { VirtualAccount } from "./pages/VirtualAccount";
import { Emandate } from "./pages/Emandate";
import { Restaurants } from "./pages/Restaurant/Restaurants";
import { RestaurantInfo } from "./pages/Restaurant/RestaurantInfo";
import { CustomerCare } from "./pages/CustomerService/CustomerCare";
import { Settlement } from "./pages/Restaurant/Settlement";
import AddRestaurant from "./pages/Restaurant/AddRestaurant";
import InstituteUpload from "./pages/InstituteUpload";

function App() {
  return (
    <>
      <Toaster position="top-right" />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/admin-signup" element={<AdminSignup />} />
          <Route path="/dashboard" element={<BasePage />}>
            <Route index element={<Dashboard />} />
            <Route path="credit-score" element={<CreditScore />} />
            <Route path="users" element={<Users />} />
            <Route path="virtual-account" element={<VirtualAccount />} />
            <Route path="order-requests" element={<Order />} />
            <Route
              path="employed-verification1"
              element={<StageOneVerification />}
            />
            <Route
              path="self-employed-verification1"
              element={<SelfEmployedVerificationStage1 />}
            />
            <Route
              path="student-verification1"
              element={<StudentVerification />}
            />
            <Route path="institute-upload" element={<InstituteUpload />} />
            <Route path="store" element={<Store />} />
            <Route path="customer-care" element={<CustomerCare />} />
            <Route path="restaurants" element={<Restaurants />} />
            <Route
              path="restaurants/restaurant-information"
              element={<RestaurantInfo />}
            />
            <Route
              path="restaurants/add-new-restaurant"
              element={<AddRestaurant />}
            />
            <Route path="restaurants/settlement" element={<Settlement />} />
            <Route path="logistics" element={<Logistics />} />
            <Route
              path="logistics/rider-activity"
              element={<RiderActivity />}
            />
            <Route path="logistics/busy-riders" element={<BusyRiders />} />
            <Route
              path="logistics/assign-delivery"
              element={<AssignDelivery />}
            />
            <Route
              path="logistics/assigned-orders"
              element={<AssignedOrders />}
            />
            <Route
              path="logistics/all-deliveries"
              element={<AllDeliveries />}
            />
            <Route path="logistics/disbutes" element={<Disbutes />} />
            <Route path="e-mandate" element={<Emandate />} />
            <Route path="faq" element={<Faq />} />
            <Route path="assignment" element={<Assignment />} />
            <Route path="document-check" element={<DocumentCheck />} />
            <Route path="next-pay" element={<NextPay />} />
            <Route path="defaulters" element={<Defaulters />} />
            <Route path="news-update" element={<NewsUpdate />} />
            <Route path="create-admin" element={<CreateAdmin />} />
            <Route path="repayment-history" element={<Repayment />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
