import { useState, useEffect } from "react";
import axios from "axios";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";

const AdminDetails = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [stats, setStats] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDashboardStats();
  }, []);

  const getDashboardStats = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getDashboardStatistic`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setStats(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setStats({});
    }
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 mb-8 mt-[1.5rem]">
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
          Verified Users
        </h1>
        <h1 className="font-semibold text-3xl">{isLoading ? "0" : stats.verifiedCustomersCount}</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
          Total Students
        </h1>
        <h1 className="font-semibold text-3xl">{isLoading ? "0" : stats.studentCustomersCount}</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
          Total Employed
        </h1>
        <h1 className="font-semibold text-3xl">{isLoading ? "0" : stats.employedCustomersCount}</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700">
          Total Self-Employed
        </h1>
        <h1 className="font-semibold text-3xl">{isLoading ? "0" : stats.selfEmployedCustomersCount}</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
          Total Orders
        </h1>
        <h1 className="font-semibold text-3xl">{isLoading ? "0" : stats.totalOrdersCount}</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
          Total Loan 
        </h1>
        <h1 className="font-semibold text-3xl">₦{isLoading ? "0.00" : stats.totalLoans && stats.totalLoans.toFixed(2)}</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
          Total paid
        </h1>
        <h1 className="font-semibold text-3xl">₦{isLoading ? "0.00" :  stats.totalPaidLoans &&  stats.totalPaidLoans.toFixed(2)}</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
          Total bad loan
        </h1>
        <h1 className="font-semibold text-3xl">₦3,000</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
          Expected for this month
        </h1>
        <h1 className="font-semibold text-3xl">₦{isLoading ? "0.00" : stats.totalExpectedPayments && stats.totalExpectedPayments.toFixed(2)}</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
          faqs count
        </h1>
        <h1 className="font-semibold text-3xl">{isLoading ? "0" : stats.faqsCount}</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
          Total disapprovals
        </h1>
        <h1 className="font-semibold text-3xl">{isLoading ? "0" : stats.totalDisapprovedOrdersCount}</h1>
      </div>
      <div
        className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
          Total approved
        </h1>
        <h1 className="font-semibold text-3xl">{isLoading ? "0" : stats.totalApprovedOrdersCount}</h1>
        {/* <div className="flex items-center text-xs pt-3">
          <p className="flex items-center bg-[#FCEBEA] border text-[#E5322D] p-1 rounded-2xl border-[#E5322D]">
            <FaArrowDownLong /> {stats.lastMonthtotalCompletedOrders}%{" "}
          </p>
          <p className="pl-2 text-gray-600">Compared to last month</p>
        </div> */}
      </div>
    </div>
  );
};

export default AdminDetails;
