import axios from "axios";
import { useState, ChangeEvent, useEffect } from "react";
import toast from "react-hot-toast";
import { useAppDispatch } from "../redux/hooks";
import { deleteBanner, getAllBanner } from "../redux/thunk";
import LoadingSpinner from "../components/elements/LoadingSpinner";

export const NewsUpdate: React.FC = () => {
  const [fileInput, setFileInput] = useState<File | null>(null);
  const [file, setFile] = useState("");
  const [postedBanners, setPostedBanners] = useState<any>([]);
  const [isloading, setIsLoading] = useState(false);
  const auth: any = sessionStorage.getItem("authState");
  const jsonData = JSON.parse(auth);
  const token = jsonData.token;

  const dispatch = useAppDispatch();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFileInput(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const postBannerHandler = async () => {
    if (!fileInput) return;
    const formData = new FormData();
    formData.append("image", fileInput);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/addBanner`,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(res.data);
      toast.success("Banner uploaded successfully!");
      handleClear();
      dispatch(getAllBanner()).then((response: any) => {
        setPostedBanners(response.payload);
      });
      
    } catch (error) {
      toast.error("Error posting image!");
      console.error("Error posting image:", error);
    }
  };

  const handleDeleteBanner = (id: string) => {
    dispatch(deleteBanner(id)).then((response: any) => {
      dispatch(getAllBanner()).then((response: any) => {
        setPostedBanners(response.payload);
      });
      
      toast.success(response.payload.message);
    });
  };

  useEffect(() => {
    setIsLoading((prev) => !prev);
    dispatch(getAllBanner()).then((response: any) => {
      setPostedBanners(response.payload);
      setIsLoading((prev) => !prev);
    });
  }, [dispatch]);

  const handleClear = () => {
    setFile("");

    const fileInputs = document.querySelector('input[type="file"]') as any;
    fileInputs.value = "";
  };  

  return (
    <main className="w-full">
      <div className="bg-white rounded-md shadow-md p-6">
        <h1 className="font-semibold text-lg mb-6">Add Image for the banner</h1>
        <div className="flex justify-between">
          <div>
            <span className="block mb-3">
              <label htmlFor="image">Image:</label>
              <input
                type="file"
                name="image"
                className="block border p-3 my-2 text-sm"
                id="image"
                onChange={handleFileChange}
              />
            </span>
          </div>
          <div className="border w-1/2 p-5">
            <span className="text-sm">Preview: </span>
            {file && (
              <img
                src={file}
                alt="Preview"
                className="my-2 max-h-[450px] w-full"
                style={{ maxWidth: "100%" }}
              />
            )}
          </div>
        </div>
        <button
          className="bg-secondary rounded-md shadow-md hover:bg-green-700 text-white px-4 py-2 mt-4"
          onClick={postBannerHandler}
        >
          Post Image
        </button>
        <button
          type="button"
          className="p-2 w-20 ml-3 text-white bg-red-500 rounded-md"
          onClick={handleClear}
        >
          Clear
        </button>
      </div>
      <div className="shadow-md bg-white p-4 mt-10 rounded-md">
        <table className="w-full">
          <thead className="text-left">
            <tr>
              <th className={isloading ? "hidden" : "block"}>SN</th>
              <th className="">Images Posted</th>
              <th className={isloading ? "hidden" : "block"}></th>
            </tr>
          </thead>
          <tbody>
            {isloading ? (
              <tr>
                <td colSpan={3} className="">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : postedBanners && postedBanners.length > 0 ? (
              postedBanners.map((item: any, index: any) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className="w-40 overflow-hidden">
                    <a href={item.image} target="blank">
                      {item.image}
                    </a>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="p-2 w-20 ml-3 text-white bg-red-500 text-sm rounded-md"
                      onClick={() => handleDeleteBanner(item._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center text-secondary">
                  No posted banners
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </main>
  );
};
