import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideBarProps } from "../types/types";
import { RxDashboard } from "react-icons/rx";
import { TbLogout2 } from "react-icons/tb";
import Back from "./Back";
import { FaQuestion, FaRegNewspaper, FaUsers } from "react-icons/fa6";
import {
  IoDocumentsOutline,
  IoPersonAddOutline,
  IoStorefrontOutline,
} from "react-icons/io5";
import {
  MdChecklist,
  MdDomainVerification,
  MdOutlineAccountBalance,
  MdOutlineAssignment,
  MdOutlineDeliveryDining,
  MdOutlineHistory,
  MdOutlineMoney,
  MdOutlinePersonOff,
  MdOutlineRestaurantMenu,
  MdSchool,
} from "react-icons/md";
import { HiOutlineCommandLine } from "react-icons/hi2";
import { GoUnverified, GoVerified } from "react-icons/go";
import { RiCustomerService2Line } from "react-icons/ri";

const SideBar = ({
  tabNavigation,
  toggleTabNavigation,
  openModal,
  role,
}: SideBarProps) => {
  const navigate = useNavigate();
  const image: any = sessionStorage.getItem("image");
  const fullName = sessionStorage.getItem("name");
  const userEmail = sessionStorage.getItem("userEmail");
  const adminLevel = sessionStorage.getItem("adminLevel");

  const tabs: any = [
    {
      title: (
        <div className="flex items-center">
          <RxDashboard className="w-6 h-6" />
          <span className="pl-2">Dashboard</span>
        </div>
      ),
      query: "dashboard",
    },
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <FaRegNewspaper className="w-6 h-6" />
              <span className="pl-2">News Update</span>
            </div>
          ),
          query: "dashboard/news-update",
        }
      : role && role.some((data: any) => data.role === "News Update")
      ? {
          title: (
            <div className="flex items-center">
              <FaRegNewspaper className="w-6 h-6" />
              <span className="pl-2">News Update</span>
            </div>
          ),
          query: "dashboard/news-update",
        }
      : {},
    {
      title: (
        <div className="flex items-center">
          <IoStorefrontOutline className="w-6 h-6" />
          <span className="pl-2">Store</span>
        </div>
      ),
      query: "dashboard/store",
    },
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineDeliveryDining className="w-6 h-6" />
              <span className="pl-2">Logistics</span>
            </div>
          ),
          query: "dashboard/logistics",
        }
      : role && role.some((data: any) => data.role === "Logistics")
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineDeliveryDining className="w-6 h-6" />
              <span className="pl-2">Logistics</span>
            </div>
          ),
          query: "dashboard/logistics",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineRestaurantMenu className="w-6 h-6" />
              <span className="pl-2">Restaurants</span>
            </div>
          ),
          query: "dashboard/restaurants",
        }
      : role && role.some((data: any) => data.role === "Restaurants")
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineRestaurantMenu className="w-6 h-6" />
              <span className="pl-2">Restaurants</span>
            </div>
          ),
          query: "dashboard/restaurants",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineAssignment className="w-6 h-6" />
              <span className="pl-2">Assignment</span>
            </div>
          ),
          query: "dashboard/assignment",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineAccountBalance className="w-6 h-6" />
              <span className="pl-2">Virtual Account</span>
            </div>
          ),
          query: "dashboard/virtual-account",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <GoUnverified className="w-6 h-6" />
              <span className="pl-2"> E-Verification 1</span>
            </div>
          ),
          query: "dashboard/employed-verification1",
        }
      : role && role.some((data: any) => data.role === "E-Verification 1")
      ? {
          title: (
            <div className="flex items-center">
              <GoUnverified className="w-6 h-6" />
              <span className="pl-2"> E-Verification 1</span>
            </div>
          ),
          query: "dashboard/employed-verification1",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <GoVerified className="w-6 h-6" />
              <span className="pl-2"> SE-Verification 1</span>
            </div>
          ),
          query: "dashboard/self-employed-verification1",
        }
      : role && role.some((data: any) => data.role === "SE-Verification 1")
      ? {
          title: (
            <div className="flex items-center">
              <GoVerified className="w-6 h-6" />
              <span className="pl-2"> SE-Verification 1</span>
            </div>
          ),
          query: "dashboard/self-employed-verification1",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdDomainVerification className="w-6 h-6" />
              <span className="pl-2"> S-Verification 1</span>
            </div>
          ),
          query: "dashboard/student-verification1",
        }
      : role && role.some((data: any) => data.role === "S-Verification 1")
      ? {
          title: (
            <div className="flex items-center">
              <MdDomainVerification className="w-6 h-6" />
              <span className="pl-2"> S-Verification 1</span>
            </div>
          ),
          query: "dashboard/student-verification1",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <IoDocumentsOutline className="w-6 h-6" />
              <span className="pl-2">Document Check</span>
            </div>
          ),
          query: "dashboard/document-check",
        }
      : role && role.some((data: any) => data.role === "Document Check")
      ? {
          title: (
            <div className="flex items-center">
              <IoDocumentsOutline className="w-6 h-6" />
              <span className="pl-2">Document Check</span>
            </div>
          ),
          query: "dashboard/document-check",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdSchool className="w-6 h-6" />
              <span className="pl-2">Institute Upload</span>
            </div>
          ),
          query: "dashboard/institute-upload",
        }
      : role && role.some((data: any) => data.role === "Institute Upload")
      ? {
          title: (
            <div className="flex items-center">
              <MdSchool className="w-6 h-6" />
              <span className="pl-2">Institute Upload</span>
            </div>
          ),
          query: "dashboard/institute-upload",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineMoney className="w-6 h-6" />
              <span className="pl-2">Next Pay</span>
            </div>
          ),
          query: "dashboard/next-pay",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <HiOutlineCommandLine className="w-6 h-6" />
              <span className="pl-2">E-mandate</span>
            </div>
          ),
          query: "dashboard/e-mandate",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <FaUsers className="w-6 h-6" />
              <span className="pl-2">Users</span>
            </div>
          ),
          query: "dashboard/users",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdChecklist className="w-6 h-6" />
              <span className="pl-2">Orders</span>
            </div>
          ),
          query: "dashboard/order-requests",
        }
      : role && role.some((data: any) => data.role === "Orders")
      ? {
          title: (
            <div className="flex items-center">
              <MdChecklist className="w-6 h-6" />
              <span className="pl-2">Orders</span>
            </div>
          ),
          query: "dashboard/order-requests",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlinePersonOff className="w-6 h-6" />
              <span className="pl-2">Defaulters</span>
            </div>
          ),
          query: "dashboard/defaulters",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <IoPersonAddOutline className="w-6 h-6" />
              <span className="pl-2">Create Admin</span>
            </div>
          ),

          query: "dashboard/create-admin",
        }
      : {},
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <RiCustomerService2Line className="w-6 h-6" />
              <span className="pl-2">Customer Care</span>
            </div>
          ),
          query: "dashboard/customer-care",
        }
      : role && role.some((data: any) => data.role === "Customer Care")
      ? {
          title: (
            <div className="flex items-center">
              <RiCustomerService2Line className="w-6 h-6" />
              <span className="pl-2">Customer Care</span>
            </div>
          ),
          query: "dashboard/customer-care",
        }
      : {},
    {
      title: (
        <div className="flex items-center">
          <FaQuestion className="w-6 h-6" />
          <span className="pl-2">FAQ</span>
        </div>
      ),
      query: "dashboard/faq",
    },
    adminLevel === "superadmin"
      ? {
          title: (
            <div className="flex items-center">
              <MdOutlineHistory className="w-6 h-6" />
              <span className="pl-2">Repayment History</span>
            </div>
          ),
          query: "dashboard/repayment-history",
        }
      : {},
  ];

  const [currentTab, setCurrentTab] = useState(
    window.location.pathname.replace("/", "") || "dashboard"
  );

  const handleOverlayClick = () => {
    toggleTabNavigation();
  };

  const handleTabChange = (tab: any) => {
    setCurrentTab(tab);
    navigate(`/${tab}`);
    toggleTabNavigation();
  };

  return (
    <>
      <div
        className={`fixed hidden top-0 h-screen inset-0 bg-black  opacity-50 z-40 ${
          tabNavigation ? "block" : "hidden"
        }`}
        onClick={handleOverlayClick}
      ></div>
      <ul
        className={`w-[70%] lg:w-[20%] overflow-y-auto shadow-md fixed bg-white p-4 h-full ${
          !tabNavigation
            ? "hidden lg:block"
            : "flex flex-col sm:items-center justify-between z-50 bg-white text-black h-full animate-fadeIn"
        }`}
      >
        <div className="w-full mb-auto flex flex-col justify-between h-full">
          <div>
            <div className="flex items-center justify-between mb-[3rem] p-2  ">
              <img
                src="/assets/logo.png"
                alt="logo"
                className=" self-center w-[6rem] "
              />
              <Back />
            </div>
            {tabs.map((tab: any, index: Number) =>
              !tab || !Object.keys(tab).length ? (
                <React.Fragment key={`${index}`}></React.Fragment>
              ) : (
                <div
                  key={tab.query}
                  className="pb-2"
                  onClick={() => handleTabChange(tab.query)}
                >
                  <li
                    className={`text-xs cursor-pointer ${
                      tab.query === currentTab
                        ? "border border-secondary200 bg-secondary100 text-secondary w-full py-1 rounded-md"
                        : ""
                    } p-2`}
                  >
                    {tab.title}
                  </li>
                </div>
              )
            )}{" "}
          </div>
          <div className="mt-auto flex flex-col mb-4">
            <div
              onClick={openModal}
              className="bg-[#ECE9F1] flex items-center mb-4 rounded-md text-sm py-3 px-1 cursor-pointer border-2 "
            >
              <TbLogout2 className="w-6 h-6" />
              <span className="pl-4">Log Out</span>
            </div>
            <div className="flex items-start flex-col xl:flex-row">
              <div>
                <h1 className="font-semibold capitalize">{fullName}</h1>
                <p className="text-gray-600 text-sm">{userEmail}</p>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </>
  );
};

export default SideBar;
